<template>
  <b-card
      title="设备管理"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="设备状态"
              label-for="input-lg"
          >
            <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="queryParams.deviceStatus"
                :options="deviceStatusOptions"
                v-model="queryParams.deviceStatus"
                class="w-100"
                :reduce="val => val.value"
                @input="(val) => $emit('update:roleFilter', val)"
                placeholder="请选择设备状态"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="设备ID"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入设备ID"
                v-model="queryParams.deviceId"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="4"
              label-size="lg"
              label="产品名称"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入产品名称"
                v-model="queryParams.productName"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="标签名称"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder="请输入标签名称"
                v-model="queryParams.tagName"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
<!--          <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-size="lg"
              label="机构"
              label-for="input-lg"
          >
            <b-form-input
                id="input-lg"
                placeholder=""
            />
          </b-form-group>-->
        </b-col>
        <b-col md="2" class="tRight">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"  @click="searchButtonClick">
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
          class="mb-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addTagButtonClick"
      >
        批量加标签
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :sort-options="{
        enabled: false,
      }"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        theme="my-theme"
        @on-selected-rows-change="onSelectChange"
    >
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1"/>
          <span>{{ props.row.fullName }}</span>
        </span>

        <span v-if="props.column.field === 'deviceStatus'" class="text-nowrap">
          <span>{{ formatterDeviceStatus(props.row.deviceStatus) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'simpleTagVos'">
          <b-button
              v-for="(item,i) in props.row.simpleTagVos"
              :key="i"
              v-ripple.400="'rgba(22, 155, 213, 0.15)'"
              variant="info"
              class="btn-sm mr-50 mb-50"
              badge="4"
          >
              {{ item.tagName }}
              <feather-icon icon="XIcon" size="12" @click="deleteTag(props.row,item)"/>
          </b-button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="tCenter">
          <b-pagination
              v-model="queryParams.currentPage"
              :total-rows="queryParams.total"
              :per-page="queryParams.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="currentPageHandleChange"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </vue-good-table>
    <b-modal
        id="modal-relation"
        title="设置标签"
        centered
        ok-only
        ok-title="确定"
        scrollable
        @ok="addTagToDevice"
    >
      <div style="height: 350px">
        <v-select placeholder="请选择标签"  :options="tags" :filterable="false" class="w-1000" multiple @input="onSelectTag">
          <li slot="list-footer" class="pagination">
            <button :disabled="!hasPrevPage" @click.stop.prevent="tagPrePage">上一页</button>
            <button :disabled="!hasNextPage" @click.stop.prevent="tagNextPage">下一页</button>
          </li>
        </v-select>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton, BModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      deviceStatusOptions: [
        {
          value: 0,
          label: '未激活'
        }
      ],
      roleFilter: null,
      columns: [
        {
          label: '设备ID',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        },
        {
          label: '标签',
          field: 'simpleTagVos',
        },
      ],
      rows: [],
      searchTerm: '',
      dir: 'ltr',
      tags: [],
      search: '',
      offset: 0,
      limit: 10,
      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: null,
        productName: null,
        deviceStatus: null,
        deviceId: null,
        tagName:null,
      },

      tagQueryParams:{
        currentPage: 1,
        pageSize: 5,
        total: null,
      },

      batchTagIds: [],
      selectRows: [],
      selectRowIds: [],

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },

    hasNextPage() {
      return this.tagQueryParams.currentPage*this.tagQueryParams.pageSize<this.tagQueryParams.total;
    },

    hasPrevPage() {
      return this.tagQueryParams.currentPage>1;
    },

  },
  created() {
    this.getTableList();
    this.deviceStatusOptionsFormat();
    this.pageTag();
  },
  methods: {

    getTableList() {
      request.post('tob/tag/bTagDevice/pageDeviceConfigForTag', {
        pageSize: this.queryParams.pageSize,
        currentPage: this.queryParams.currentPage,
        productName: this.queryParams.productName,
        deviceStatus: this.queryParams.deviceStatus,
        deviceId: this.queryParams.deviceId,
        tagName: this.queryParams.tagName,
      }).then(res => {
            if (res.data.success) {
              this.rows = res.data.data.data
              this.queryParams.total = res.data.data.count
            } else {
              that.$refs.loginForm.setErrors(res.data.msg)
            }
          })
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getTableList()
    },

    //格式化后端返回的事件处理状态枚举
    deviceStatusOptionsFormat() {
      request.get('tob/bDevice/getDeviceStatusEnums', {
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.deviceStatusOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.deviceStatusOptions.push(enumObject);
          }
        }
      })
    },

    formatterDeviceStatus(val) {
      for (let item of this.deviceStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },

    searchButtonClick() {
      this.queryParams.currentPage = 1
      this.getTableList()
    },

    pageTag(){
      request.get('tob/tag/bTag/getAllTagSimple', { currentPage: this.tagQueryParams.currentPage, pageSize: this.tagQueryParams.pageSize }).then(res => {
        if (res.data.success) {
          let data = res.data.data.data
          this.tagQueryParams.total = res.data.data.count
          this.tags = [];
          for (const key in data) {
            var value = data[key];
            var enumObject = {
              value: value.tagId,
              label: value.tagName,
            }
            this.tags.push(enumObject);
          }
        }
      })
    },

    tagNextPage(){
      this.tagQueryParams.currentPage=this.tagQueryParams.currentPage+1;
      this.pageTag();
    },

    tagPrePage(){
      this.tagQueryParams.currentPage=this.tagQueryParams.currentPage-1;
      this.pageTag();
    },

    onSelectTag(val) {
      this.batchTagIds = [];
      for (const valElement of val) {
        this.batchTagIds.push(valElement.value);
      }
    },

    onSelectChange(params) {
      this.selectRows = params.selectedRows;
      this.selectRowIds = [];
      for (const argument of this.selectRows) {
        this.selectRowIds.push(argument.deviceConfigId);
      }
    },

    deleteTag(row, item) {
      let tagName = item.tagName;
      let tagId = item.tagId;

      request.delete('tob/tag/bTagDevice/deleteTagToDevice', { deviceConfigId: row.deviceConfigId, tagId: tagId }).then(res => {
        if (res.data.success) {
          this.$message.success('删除成功')
          this.getTableList()
        }
      })
    },

    addTagToDevice(params) {
      if (this.selectRowIds.length <= 0) {
        this.$message.error('请先选择设备') ;
        return ;
      }

      if (this.batchTagIds.length <= 0) {
        this.$message.error('请选择要增加的标签') ;
        return ;
      }

      params.preventDefault();//禁止关闭
      request.post('tob/tag/bTagDevice/addTagToDevice', { deviceConfigIds: this.selectRowIds, tagIds: this.batchTagIds }).then(res => {
        if (res.data.success) {
          this.$message.success('关联成功')
          this.$nextTick(() => {//关闭
            this.$bvModal.hide('modal-relation')
          })
          this.getTableList()
        }
      })
    },

    addTagButtonClick() {
      if (this.selectRowIds.length <= 0) {
        this.$message.error('请先选择设备') ;
        return ;
      }

      this.$bvModal.show('modal-relation');
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
